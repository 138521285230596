<script setup>

import { router } from '@inertiajs/vue3';
import Badge from '@/Components/Badge.vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    content: {
        type: String,
        default: null
    },
    featureImage: null,
    bgClass: {
        type: String,
        default: 'bg-contrast-primary',
    },
    firstOrderImage: {
        type: Boolean,
        default: false
    },
    titleClass: {
        type: String,
        default: 'mb-3 text-3xl lg:text-4xl'
    },
    link: {
        type: String,
        default: null
    },
    badgeText: {
        type: String,
        default: null
    }
});

const redirect = () => {
    if(props.link) {
        router.visit(props.link);
    }
};

</script>

<template>
    <div class="group rounded-2xl" :class="[props.bgClass, [props.link ? 'cursor-pointer transition-transform transform hover:-translate-y-2' : '']]" @click="redirect()">
        <div class="flex flex-col lg:flex-row">
            <div class="flex content-center items-center w-full px-4 sm:px-16 py-8">
                <div class="text-accent">
                    <div v-if="props.badgeText" class="mb-3">
                        <Badge :message="props.badgeText" type="primary"></Badge>
                    </div>
                    <h2 class="mb-3" :class="props.titleClass">
                        {{ props.title }}
                    </h2>
                    <p v-if="props.content" class="text-pretty">
                        {{ props.content }}
                    </p>
                    <div v-if="props.link" class="opacity-100 lg:opacity-0 group-hover:opacity-100 mt-4 transition-all duration-300">
                        <div class="btn btn-warning w-full lg:w-auto">
                            {{ $t('actions.learn_more') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full" :class="{ 'lg:order-first' : props.firstOrderImage }">
                <img :src="props.featureImage" alt="Feature" class="w-full rounded-2xl">
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
